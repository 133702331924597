import type { Telegram } from '../types'

declare global {
  // eslint-disable-next-line -- why var see in https://stackoverflow.com/questions/59459312/using-globalthis-in-typescript
  var Telegram: Telegram
}
interface AuthData {
  language: string
  promocodeName?: string
  referralCode?: string
}

export function useTelegram() {
  const stFetch = useRawStFetch()

  const telegram: Telegram['WebApp'] = globalThis?.Telegram?.WebApp

  const isTelegramMiniApp = computed(() =>
    telegram ? Object.keys(telegram.initDataUnsafe).length !== 0 : false,
  )
  async function auth(data: AuthData) {
    if (!telegram?.initDataUnsafe?.user?.id)
      throw new Error('Telegram auth error')

    const { data: userData } = await stFetch('/auth/telegram/mini-app', {
      method: 'post',
      body: {
        language: data.language as any,
        promocodeName: data.promocodeName,
        referralCode: data.referralCode,
        tgData: telegram.initData,
      },
    })

    return userData
  }

  return {
    isTelegramMiniApp,
    telegram,
    auth,
  }
}
