interface CookiesStorage {
  functional: boolean
  analytic: boolean
  configured: boolean
}

export const useCookiesStore = defineStore('cookie', () => {
  const cookiesStorage = useLocalStorage<CookiesStorage>('cookiesStorage', {
    functional: true,
    analytic: true,
    configured: false,
  })

  const functionalCookie = computed({
    get() {
      return cookiesStorage.value.functional
    },
    set(newValue) {
      cookiesStorage.value.functional = newValue
    },
  })

  const analyticCookie = computed({
    get() {
      return cookiesStorage.value.analytic
    },
    set(newValue) {
      cookiesStorage.value.analytic = newValue
    },
  })

  const isCookieConfigured = computed(() => cookiesStorage.value.configured)

  function configCookie() {
    cookiesStorage.value.configured = true
  }

  return {
    functionalCookie,
    analyticCookie,
    isCookieConfigured,
    cookiesStorage,
    configCookie,
  }
})
