import { useUserStore } from '@st/user/stores/useUserStore'
import { useAccountsStore } from '@st/payments/stores/useAccountsStore'
import type { UserAccount } from '@st/payments/interfaces'
import type { FreebetRaw } from '../types'

export const useFreebetsStore = defineStore('freebets', () => {
  const stFetch = useRawStFetch()
  const { getAccountById } = useAccountsStore()
  const io = useSocket()
  const isLoading = ref(false)
  const freebetsRaw = ref<FreebetRaw[]>([])

  async function fetchFreebets() {
    let total = 0
    let page = 0
    const items: FreebetRaw[] = []

    isLoading.value = true

    do {
      // eslint-disable-next-line no-await-in-loop
      const { data } = await stFetch('/freebet/find', {
        method: 'post',
        body: {
          params: {
            processed: false,
          },
          pagination: {
            page,
            perPage: 50,
            orderBy: [
              {
                fieldName: 'createdAt',
                sortOrder: 'DESC',
              },
            ],
          },
        },
      })
      items.push(...data.data)
      total = data.paging.total
      page += 1
    } while (items.length < total)

    freebetsRaw.value = items
    isLoading.value = false
  }

  const freebets = computed(
    () =>
      freebetsRaw.value?.map((freebet: FreebetRaw) => {
        const account = getAccountById(freebet.accountId) as UserAccount

        return {
          ...freebet,
          currencyId: account?.currencyId,
          currencyCode: account?.code,
          currencyIcon: account?.icon,
        }
      }),
  )

  /*
    фрибеты без бонусной программы за депозит
  */
  const notDepositFreebets = computed(() =>
    freebets.value.filter((freebet) => !freebet.bonusForDepositProgram),
  )

  /*
    фрибеты с бонусной программой за депозит
  */
  const depositFreebets = computed(() =>
    freebets.value.filter((freebet) => freebet.bonusForDepositProgram),
  )

  const notUsedFreebets = computed(() =>
    freebets.value.filter((freebet: FreebetRaw) => !freebet.betId),
  )

  io.on('betStatus', ({ betStatus }) => {
    if (betStatus === 'accepted' && freebets.value.length > 0) {
      fetchFreebets()
    }
  })

  io.on('welcomeProgram', fetchFreebets)

  const count = computed(() => freebets.value.length)

  const { isAuthenticated } = storeToRefs(useUserStore())
  watchEffect(() => {
    if (isAuthenticated.value) {
      fetchFreebets()
    } else {
      freebetsRaw.value = []
    }
  })

  return {
    freebets,
    notDepositFreebets,
    depositFreebets,
    notUsedFreebets,
    freebetsRaw,
    fetchFreebets,
    count,
    isLoading,
  }
})
