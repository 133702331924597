import { mockDateWithOffset } from '@st/utils'
import { HttpResponse, stHttp } from '../st-msw'

export const postBonusFind = stHttp.post('/bonus/find', async ({ request }) => {
  const params = await request.json()
  const isDepositProgram =
    params?.params?.bonusProgramType?.includes('bonusForDeposit')

  if (isDepositProgram) {
    return HttpResponse.json({
      paging: {
        page: 0,
        count: 0,
        total: 3,
      },
      data: [
        {
          id: 123,
          currencyId: 1,
          createdAt: '2024-07-11T13:00:07.705Z',
          bonusProgramType: 'bonusForDeposit',
          bonusProgramId: 1,
          claimed: false,
          expiredAt: mockDateWithOffset({ days: 2 }),
          amount: '1.25',
          bonusForDepositProgram: {
            id: 1,
            level: 1,
            minDepositAmountInAppCurrency: '10',
            maxBonusAmountInBonusCurrency: '1000',
            depositMultiplier: '1',
          },
          rollingSettings: {
            casinoCategoryIds: [1, 2, 3],
            minBetRate: '1',
            maxBetRate: '2',
            multiplier: '10',
          },
          rolling: {
            id: 1,
            currencyId: 1,
            status: 'inProgress',
            fullAmount: '250',
            currentAmount: '125',
            sourceType: 'freebet',
            sourceId: 1,
            createdAt: '2020-01-01T00:00:00Z',
            updatedAt: '2020-01-01T00:00:00Z',
            expiredAt: mockDateWithOffset({ days: 2 }),
          },
        },
        {
          id: 1234,
          currencyId: 1,
          createdAt: '2024-07-11T13:00:07.705Z',
          bonusProgramType: 'bonusForDeposit',
          bonusProgramId: 1,
          claimed: false,
          expiredAt: mockDateWithOffset({ days: 2 }),
          amount: '1.25',
          bonusForDepositProgram: {
            id: 1,
            level: 1,
            minDepositAmountInAppCurrency: '10',
            maxBonusAmountInBonusCurrency: '1000',
            depositMultiplier: '1',
          },
          rollingSettings: {
            casinoCategoryIds: [1, 2, 3],
            minBetRate: '1',
            maxBetRate: '2',
            multiplier: '20',
          },
          rolling: {
            id: 1,
            currencyId: 1,
            status: 'paused',
            fullAmount: '1250',
            currentAmount: '125',
            sourceType: 'freebet',
            sourceId: 1,
            createdAt: '2020-01-01T00:00:00Z',
            updatedAt: '2020-01-01T00:00:00Z',
            expiredAt: mockDateWithOffset({ days: 2 }),
          },
        },
        {
          id: 1870,
          currencyId: 1,
          createdAt: '2024-07-11T13:00:07.705Z',
          bonusProgramType: 'bonusForDeposit',
          bonusProgramId: 1,
          claimed: false,
          expiredAt: mockDateWithOffset({ days: 2 }),
          amount: '100',
          bonusForDepositProgram: {
            id: 1,
            level: 1,
            minDepositAmountInAppCurrency: '10',
            maxBonusAmountInBonusCurrency: '1000',
            depositMultiplier: '1',
          },
          rollingSettings: {
            casinoCategoryIds: [1, 2, 3],
            minBetRate: '1',
            maxBetRate: '2',
            multiplier: '20',
          },
          rolling: {
            id: 1,
            currencyId: 1,
            status: 'inProgress',
            fullAmount: '1.25',
            currentAmount: '1.25',
            sourceType: 'freebet',
            sourceId: 1,
            createdAt: '2020-01-01T00:00:00Z',
            updatedAt: '2020-01-01T00:00:00Z',
            expiredAt: mockDateWithOffset({ days: 2 }),
          },
        },
      ] as any,
    })
  }

  return HttpResponse.json({
    paging: {
      page: 0,
      count: 0,
      total: 3,
    },
    data: [
      {
        id: 1,
        currencyId: 1,
        createdAt: '2024-07-11T13:00:07.705Z',
        bonusProgramType: 'rakeBack',
        bonusProgramId: 1,
        claimed: false,
        expiredAt: mockDateWithOffset({ seconds: 10 }),
        amount: '1.25',
        bonusForDepositProgram: null,
        rolling: null,
        rollingSettings: null,
      },
      {
        id: 12,
        currencyId: 1,
        createdAt: '2024-07-11T13:00:07.705Z',
        bonusProgramType: 'cashback',
        bonusProgramId: 1,
        claimed: false,
        expiredAt: mockDateWithOffset({ hours: 23 }),
        amount: '1.25',
        bonusForDepositProgram: null,
        rolling: {
          id: 123,
          currencyId: 4,
          status: 'new',
          fullAmount: '1.25',
          currentAmount: '1.25',
          sourceType: 'freebet',
          sourceId: 1,
          createdAt: '2020-01-01T00:00:00Z',
          updatedAt: '2020-01-01T00:00:00Z',
          expiredAt: '2020-01-01T00:00:00Z',
        },
        rollingSettings: null,
      },
      {
        id: 13,
        currencyId: 1,
        createdAt: '2024-07-11T13:00:07.705Z',
        bonusProgramType: 'reload',
        bonusProgramId: 1,
        claimed: false,
        expiredAt: mockDateWithOffset({ hours: 23 }),
        amount: '1.25',
        bonusForDepositProgram: {
          id: 1,
          level: 1,
          minDepositAmountInAppCurrency: '10',
          maxBonusAmountInBonusCurrency: '1000',
          depositMultiplier: '1',
        },
        rolling: null,
        rollingSettings: null,
      },
      {
        id: 16,
        currencyId: 1,
        createdAt: '2024-07-11T13:00:07.705Z',
        bonusProgramType: 'reload',
        bonusProgramId: 1,
        claimed: false,
        expiredAt: mockDateWithOffset({ hours: 23 }),
        amount: '1.25',
        bonusForDepositProgram: null,
        rolling: {
          id: 1,
          currencyId: 1,
          status: 'inProgress',
          fullAmount: '1.25',
          currentAmount: '1.25',
          sourceType: 'freebet',
          sourceId: 1,
          createdAt: '2020-01-01T00:00:00Z',
          updatedAt: '2020-01-01T00:00:00Z',
          expiredAt: '2020-01-01T00:00:00Z',
        },
        rollingSettings: null,
      },
    ],
  })
})

export const stepsBonusesMocks = [
  {
    id: 70,
    createdAt: '2024-02-01T00:00:00',
    expiredAt: mockDateWithOffset({ hours: 2 }),
    minDepositAmountInAppCurrency: '10',
    maxDepositAmountInAppCurrency: '100',
    level: 1,
    groupName: 'step',
    weightInGroup: 1,
    bonusPrograms: {
      bonusSettings: {
        currencyId: 1,
        depositMultiplier: '1',
        rollingMultiplier: '1.0',
        rollingPeriodInHours: 1,
        rollingCasinoCategoryIds: [1, 2],
        rollingMinBetRate: '1.0',
        rollingMaxBetRate: '1.0',
        maxBonusAmountInBonusCurrency: '10000',
      },
      freespinProgram: {
        freespinBetAmount: '10',
        freespinCurrencyId: 9,
        freespinNumber: 30,
        rollingMultiplier: '20',
        rollingDurationPeriodInHours: 23,
        maxRewardAmount: '100',
        rollingCasinoCategoryIds: [1, 2],
        rollingMinBetRate: '1',
        rollingMaxBetRate: '10',
      },
      reloadProgram: {
        amount: '100',
        currencyId: 1,
        bonusRepeatIntervalInHours: 10,
      },
      freebet: {
        currencyId: 1,
        amount: '100',
        freebetSettings: {
          allowedBetTypes: ['ordinary'],
          minBetRate: '1',
          maxBetRate: '100',
        },
        freebetRollingSettings: {
          rollingMultiplier: '1.0',
          rollingPeriodInHours: 1,
          rollingCasinoCategoryIds: [1, 2],
          rollingMinBetRate: '1.0',
          rollingMaxBetRate: '1.0',
        },
      },
    },
    bonusSettings: {
      currencyId: 1,
      depositMultiplier: '1',
      rollingMultiplier: '1.0',
      rollingPeriodInHours: 1,
      rollingCasinoCategoryIds: [1, 2],
      rollingMinBetRate: '1.0',
      rollingMaxBetRate: '1.0',
      maxBonusAmountInBonusCurrency: '10000',
    },
    bonus: null,
    status: 'inProgress',
    chainName: null,
  },
  {
    id: 71,
    createdAt: '2024-02-01T00:00:00',
    expiredAt: mockDateWithOffset({ hours: 2 }),
    minDepositAmountInAppCurrency: '15',
    maxDepositAmountInAppCurrency: '200',
    level: 2,
    groupName: 'step',
    weightInGroup: 2,
    bonusPrograms: {
      bonusSettings: {
        currencyId: 1,
        depositMultiplier: '2',
        rollingMultiplier: '1.0',
        rollingPeriodInHours: 1,
        rollingCasinoCategoryIds: [1, 2],
        rollingMinBetRate: '1.0',
        rollingMaxBetRate: '1.0',
        maxBonusAmountInBonusCurrency: '20000',
      },
      freespinProgram: {
        freespinBetAmount: '100',
        freespinCurrencyId: 9,
        freespinNumber: 30,
        rollingMultiplier: '20',
        rollingDurationPeriodInHours: 23,
        maxRewardAmount: '200',
        rollingCasinoCategoryIds: [1, 2],
        rollingMinBetRate: '1',
        rollingMaxBetRate: '10',
      },
      reloadProgram: {
        amount: '200',
        currencyId: 1,
        bonusRepeatIntervalInHours: 10,
      },
      freebet: {
        currencyId: 1,
        amount: '200',
        freebetSettings: {
          allowedBetTypes: ['ordinary'],
          minBetRate: '1',
          maxBetRate: '100',
        },
        freebetRollingSettings: {
          rollingMultiplier: '1.0',
          rollingPeriodInHours: 1,
          rollingCasinoCategoryIds: [1, 2],
          rollingMinBetRate: '1.0',
          rollingMaxBetRate: '1.0',
        },
      },
    },
    bonusSettings: {
      currencyId: 1,
      depositMultiplier: '100',
      rollingMultiplier: '1.0',
      rollingPeriodInHours: 1,
      rollingCasinoCategoryIds: [1, 2],
      rollingMinBetRate: '1.0',
      rollingMaxBetRate: '1.0',
      maxBonusAmountInBonusCurrency: '10000',
    },
    bonus: null,
    status: 'inProgress',
    chainName: null,
  },
  {
    id: 72,
    createdAt: '2024-02-01T00:00:00',
    expiredAt: mockDateWithOffset({ hours: 2 }),
    minDepositAmountInAppCurrency: '50',
    maxDepositAmountInAppCurrency: '200',
    level: 1,
    groupName: 'step',
    weightInGroup: 3,
    bonusPrograms: {
      bonusSettings: {
        currencyId: 1,
        depositMultiplier: '3',
        rollingMultiplier: '1.0',
        rollingPeriodInHours: 1,
        rollingCasinoCategoryIds: [1, 2],
        rollingMinBetRate: '1.0',
        rollingMaxBetRate: '1.0',
        maxBonusAmountInBonusCurrency: '30000',
      },
      freespinProgram: {
        freespinBetAmount: '200',
        freespinCurrencyId: 9,
        freespinNumber: 30,
        rollingMultiplier: '20',
        rollingDurationPeriodInHours: 23,
        maxRewardAmount: '2000',
        rollingCasinoCategoryIds: [1, 2],
        rollingMinBetRate: '1',
        rollingMaxBetRate: '10',
      },
      reloadProgram: {
        amount: '300',
        currencyId: 1,
        bonusRepeatIntervalInHours: 10,
      },
      freebet: {
        currencyId: 1,
        amount: '200',
        freebetSettings: {
          allowedBetTypes: ['ordinary'],
          minBetRate: '1',
          maxBetRate: '100',
        },
        freebetRollingSettings: {
          rollingMultiplier: '1.0',
          rollingPeriodInHours: 1,
          rollingCasinoCategoryIds: [1, 2],
          rollingMinBetRate: '1.0',
          rollingMaxBetRate: '1.0',
        },
      },
    },
    bonusSettings: {
      currencyId: 1,
      depositMultiplier: '100',
      rollingMultiplier: '1.0',
      rollingPeriodInHours: 1,
      rollingCasinoCategoryIds: [1, 2],
      rollingMinBetRate: '1.0',
      rollingMaxBetRate: '1.0',
      maxBonusAmountInBonusCurrency: '10000',
    },
    bonus: null,
    status: 'inProgress',
    chainName: null,
  },
]
export const chainBonusesMocks = [
  {
    id: 3270,
    createdAt: '2024-02-01T00:00:00',
    expiredAt: mockDateWithOffset({ hours: 2 }),
    minDepositAmountInAppCurrency: '10',
    maxDepositAmountInAppCurrency: '100',
    level: 1,
    groupName: null,
    weightInGroup: 1,
    bonusPrograms: {
      bonusSettings: {
        currencyId: 1,
        depositMultiplier: '100',
        rollingMultiplier: '1.0',
        rollingPeriodInHours: 1,
        rollingCasinoCategoryIds: [1, 2],
        rollingMinBetRate: '1.0',
        rollingMaxBetRate: '1.0',
        maxBonusAmountInBonusCurrency: '10000',
      },
      freespinProgram: {
        freespinBetAmount: '10',
        freespinCurrencyId: 9,
        freespinNumber: 30,
        rollingMultiplier: '20',
        rollingDurationPeriodInHours: 23,
        maxRewardAmount: '100',
        rollingCasinoCategoryIds: [1, 2],
        rollingMinBetRate: '1',
        rollingMaxBetRate: '10',
      },
      reloadProgram: {
        amount: '100',
        currencyId: 1,
        bonusRepeatIntervalInHours: 10,
      },
      freebet: {
        currencyId: 1,
        amount: '100',
        freebetSettings: {
          allowedBetTypes: ['ordinary'],
          minBetRate: '1',
          maxBetRate: '100',
        },
        freebetRollingSettings: {
          rollingMultiplier: '1.0',
          rollingPeriodInHours: 1,
          rollingCasinoCategoryIds: [1, 2],
          rollingMinBetRate: '1.0',
          rollingMaxBetRate: '1.0',
        },
      },
    },
    bonusSettings: {
      currencyId: 1,
      depositMultiplier: '100',
      rollingMultiplier: '1.0',
      rollingPeriodInHours: 1,
      rollingCasinoCategoryIds: [1, 2],
      rollingMinBetRate: '1.0',
      rollingMaxBetRate: '1.0',
      maxBonusAmountInBonusCurrency: '10000',
    },
    bonus: null,
    status: 'processed',
    chainName: 'chain',
  },
  {
    id: 2323,
    createdAt: '2024-02-01T00:00:00',
    expiredAt: mockDateWithOffset({ hours: 2 }),
    minDepositAmountInAppCurrency: '15',
    maxDepositAmountInAppCurrency: '200',
    level: 2,
    groupName: null,
    weightInGroup: 2,
    bonusPrograms: {
      bonusSettings: {
        currencyId: 1,
        depositMultiplier: '15',
        rollingMultiplier: '1.0',
        rollingPeriodInHours: 1,
        rollingCasinoCategoryIds: [1, 2],
        rollingMinBetRate: '1.0',
        rollingMaxBetRate: '1.0',
        maxBonusAmountInBonusCurrency: '20000',
      },
      freespinProgram: {
        freespinBetAmount: '100',
        freespinCurrencyId: 9,
        freespinNumber: 30,
        rollingMultiplier: '20',
        rollingDurationPeriodInHours: 23,
        maxRewardAmount: '200',
        rollingCasinoCategoryIds: [1, 2],
        rollingMinBetRate: '1',
        rollingMaxBetRate: '10',
      },
      reloadProgram: {
        amount: '200',
        currencyId: 1,
        bonusRepeatIntervalInHours: 10,
      },
      freebet: {
        currencyId: 1,
        amount: '200',
        freebetSettings: {
          allowedBetTypes: ['ordinary'],
          minBetRate: '1',
          maxBetRate: '100',
        },
        freebetRollingSettings: {
          rollingMultiplier: '1.0',
          rollingPeriodInHours: 1,
          rollingCasinoCategoryIds: [1, 2],
          rollingMinBetRate: '1.0',
          rollingMaxBetRate: '1.0',
        },
      },
    },
    bonusSettings: {
      currencyId: 1,
      depositMultiplier: '100',
      rollingMultiplier: '1.0',
      rollingPeriodInHours: 1,
      rollingCasinoCategoryIds: [1, 2],
      rollingMinBetRate: '1.0',
      rollingMaxBetRate: '1.0',
      maxBonusAmountInBonusCurrency: '10000',
    },
    bonus: null,
    status: 'inProgress',
    chainName: 'chain',
  },
  {
    id: 71232,
    createdAt: '2024-02-01T00:00:00',
    expiredAt: mockDateWithOffset({ hours: 2 }),
    minDepositAmountInAppCurrency: '50',
    maxDepositAmountInAppCurrency: '200',
    level: 3,
    groupName: null,
    weightInGroup: 3,
    bonusPrograms: {
      bonusSettings: {
        currencyId: 1,
        depositMultiplier: '300',
        rollingMultiplier: '1.0',
        rollingPeriodInHours: 1,
        rollingCasinoCategoryIds: [1, 2],
        rollingMinBetRate: '1.0',
        rollingMaxBetRate: '1.0',
        maxBonusAmountInBonusCurrency: '30000',
      },
      freespinProgram: {
        freespinBetAmount: '200',
        freespinCurrencyId: 9,
        freespinNumber: 30,
        rollingMultiplier: '20',
        rollingDurationPeriodInHours: 23,
        maxRewardAmount: '2000',
        rollingCasinoCategoryIds: [1, 2],
        rollingMinBetRate: '1',
        rollingMaxBetRate: '10',
      },
      reloadProgram: {
        amount: '300',
        currencyId: 1,
        bonusRepeatIntervalInHours: 10,
      },
      freebet: {
        currencyId: 1,
        amount: '200',
        freebetSettings: {
          allowedBetTypes: ['ordinary'],
          minBetRate: '1',
          maxBetRate: '100',
        },
        freebetRollingSettings: {
          rollingMultiplier: '1.0',
          rollingPeriodInHours: 1,
          rollingCasinoCategoryIds: [1, 2],
          rollingMinBetRate: '1.0',
          rollingMaxBetRate: '1.0',
        },
      },
    },
    bonusSettings: {
      currencyId: 1,
      depositMultiplier: '100',
      rollingMultiplier: '1.0',
      rollingPeriodInHours: 1,
      rollingCasinoCategoryIds: [1, 2],
      rollingMinBetRate: '1.0',
      rollingMaxBetRate: '1.0',
      maxBonusAmountInBonusCurrency: '10000',
    },
    bonus: null,
    status: 'new',
    chainName: 'chain',
  },
]

export const postBonusForDepositFindResponse = [
  {
    id: 1,
    createdAt: '2024-02-01T00:00:00',
    expiredAt: mockDateWithOffset({ hours: 2 }),
    minDepositAmountInAppCurrency: '99',
    maxDepositAmountInAppCurrency: '200',
    level: 1,
    groupName: null,
    weightInGroup: 1,
    bonusPrograms: {
      bonusSettings: {
        currencyId: 1,
        depositMultiplier: '100',
        rollingMultiplier: '1.0',
        rollingPeriodInHours: 1,
        rollingCasinoCategoryIds: [1, 2],
        rollingMinBetRate: '1.0',
        rollingMaxBetRate: '1.0',
        maxBonusAmountInBonusCurrency: '10000',
      },
      freespinProgram: null,
      reloadProgram: null,
      freebet: null,
    },
    bonusSettings: {
      currencyId: 1,
      depositMultiplier: '100',
      rollingMultiplier: '1.0',
      rollingPeriodInHours: 1,
      rollingCasinoCategoryIds: [1, 2],
      rollingMinBetRate: '1.0',
      rollingMaxBetRate: '1.0',
      maxBonusAmountInBonusCurrency: '10000',
    },
    bonus: null,
    status: 'inProgress',
    chainName: null,
  } as any,
  // // multi bonus
  {
    id: 10,
    createdAt: '2024-02-01T00:00:00',
    expiredAt: mockDateWithOffset({ hours: 1 }),
    minDepositAmountInAppCurrency: '99',
    maxDepositAmountInAppCurrency: '200',
    level: 1,
    groupName: null,
    weightInGroup: 1,
    bonusPrograms: {
      bonusSettings: {
        currencyId: 1,
        depositMultiplier: '100',
        rollingMultiplier: '1.0',
        rollingPeriodInHours: 1,
        rollingCasinoCategoryIds: [1, 2],
        rollingMinBetRate: '1.0',
        rollingMaxBetRate: '1.0',
        maxBonusAmountInBonusCurrency: '10000',
      },
      freespinProgram: {
        freespinBetAmount: '10',
        freespinCurrencyId: 9,
        freespinNumber: 30,
        rollingMultiplier: '20',
        rollingDurationPeriodInHours: 23,
        maxRewardAmount: '100',
        rollingCasinoCategoryIds: [1, 2],
        rollingMinBetRate: '1',
        rollingMaxBetRate: '10',
      },
      reloadProgram: {
        amount: '100',
        currencyId: 1,
        bonusRepeatIntervalInHours: 10,
      },
      freebet: {
        currencyId: 1,
        amount: '100',
        freebetSettings: {
          allowedBetTypes: ['ordinary'],
          minBetRate: '1',
          maxBetRate: '100',
        },
        freebetRollingSettings: {
          rollingMultiplier: '1.0',
          rollingPeriodInHours: 1,
          rollingCasinoCategoryIds: [1, 2],
          rollingMinBetRate: '1.0',
          rollingMaxBetRate: '1.0',
        },
      },
    },
    bonusSettings: {
      currencyId: 1,
      depositMultiplier: '100',
      rollingMultiplier: '1.0',
      rollingPeriodInHours: 1,
      rollingCasinoCategoryIds: [1, 2],
      rollingMinBetRate: '1.0',
      rollingMaxBetRate: '1.0',
      maxBonusAmountInBonusCurrency: '10000',
    },
    bonus: {
      id: 444,
      claimed: false,
      needRolling: true,
      rollingCompleted: false,
      currencyId: 3,
      amountInBonusCurrency: '1.23',
      expiredAt: '2025-02-01T00:00:00',
      currentRollingAmountInBonusCurrency: '1',
      fullRollingAmountInBonusCurrency: '1.25',
      rolling: {
        id: 1,
        currencyId: 1,
        status: 'new',
        fullAmount: '1.25',
        currentAmount: '1.25',
        sourceType: 'freebet',
        sourceId: 1,
        createdAt: '2020-01-01T00:00:00Z',
        updatedAt: '2020-01-01T00:00:00Z',
        expiredAt: '2020-01-01T00:00:00Z',
      },
    },
    status: 'inProgress',
    chainName: null,
  },
  // reload for deposit
  {
    id: 100,
    createdAt: '2024-02-01T00:00:00',
    expiredAt: mockDateWithOffset({ hours: 5 }),
    minDepositAmountInAppCurrency: '99',
    maxDepositAmountInAppCurrency: '200',
    level: 1,
    groupName: null,
    weightInGroup: 1,
    bonusPrograms: {
      bonusSettings: null,
      freespinProgram: null,
      reloadProgram: {
        amount: '100',
        currencyId: 1,
        bonusRepeatIntervalInHours: 10,
      },
      freebet: null,
    },
    bonusSettings: {
      currencyId: 1,
      depositMultiplier: '100',
      rollingMultiplier: '1.0',
      rollingPeriodInHours: 1,
      rollingCasinoCategoryIds: [1, 2],
      rollingMinBetRate: '1.0',
      rollingMaxBetRate: '1.0',
      maxBonusAmountInBonusCurrency: '10000',
    },
    bonus: {
      id: 555,
      claimed: false,
      needRolling: true,
      rollingCompleted: false,
      currencyId: 3,
      amountInBonusCurrency: '1.23',
      expiredAt: '2025-02-01T00:00:00',
      currentRollingAmountInBonusCurrency: '1',
      fullRollingAmountInBonusCurrency: '1.25',
      rolling: {
        id: 15,
        currencyId: 4,
        status: 'paused',
        fullAmount: '1.25',
        currentAmount: '1.25',
        sourceType: 'freebet',
        sourceId: 1,
        createdAt: '2020-01-01T00:00:00Z',
        updatedAt: '2020-01-01T00:00:00Z',
        expiredAt: '2025-01-01T00:00:00Z',
      },
    },
    status: 'inProgress',
    chainName: null,
  },
  // freespin for deposit
  {
    id: 190,
    createdAt: '2024-02-01T00:00:00',
    expiredAt: mockDateWithOffset({ hours: 2 }),
    minDepositAmountInAppCurrency: '99',
    maxDepositAmountInAppCurrency: '200',
    level: 1,
    groupName: null,
    weightInGroup: 1,
    bonusPrograms: {
      bonusSettings: null,
      freespinProgram: {
        freespinBetAmount: '10',
        freespinCurrencyId: 9,
        freespinNumber: 30,
        rollingMultiplier: '20',
        rollingDurationPeriodInHours: 23,
        maxRewardAmount: '100',
        rollingCasinoCategoryIds: [1, 2],
        rollingMinBetRate: '1',
        rollingMaxBetRate: '10',
      },
      reloadProgram: null,
      freebet: null,
    },
    bonusSettings: {
      currencyId: 1,
      depositMultiplier: '100',
      rollingMultiplier: '1.0',
      rollingPeriodInHours: 1,
      rollingCasinoCategoryIds: [1, 2],
      rollingMinBetRate: '1.0',
      rollingMaxBetRate: '1.0',
      maxBonusAmountInBonusCurrency: '10000',
    },
    bonus: null,
    status: 'inProgress',
    chainName: null,
  },
  // freebet for deposit
  {
    id: 3210,
    createdAt: '2024-02-01T00:00:00',
    expiredAt: mockDateWithOffset({ hours: 2 }),
    minDepositAmountInAppCurrency: '99',
    maxDepositAmountInAppCurrency: '200',
    level: 1,
    groupName: null,
    weightInGroup: 1,
    bonusPrograms: {
      bonusSettings: null,
      freespinProgram: null,
      reloadProgram: null,
      freebet: {
        currencyId: 1,
        amount: '100',
        freebetSettings: {
          allowedBetTypes: ['ordinary'],
          minBetRate: '1',
          maxBetRate: '100',
        },
        freebetRollingSettings: {
          rollingMultiplier: '1.0',
          rollingPeriodInHours: 1,
          rollingCasinoCategoryIds: [1, 2],
          rollingMinBetRate: '1.0',
          rollingMaxBetRate: '1.0',
        },
      },
    },
    bonusSettings: {
      currencyId: 1,
      depositMultiplier: '100',
      rollingMultiplier: '1.0',
      rollingPeriodInHours: 1,
      rollingCasinoCategoryIds: [1, 2],
      rollingMinBetRate: '1.0',
      rollingMaxBetRate: '1.0',
      maxBonusAmountInBonusCurrency: '10000',
    },
    bonus: null,
    status: 'inProgress',
    chainName: null,
  },
  // steps bonuses
  ...stepsBonusesMocks,
  // chain bonuses
  ...chainBonusesMocks,
]
export const postBonusForDepositFind = stHttp.post(
  '/bonus-for-deposit/find',
  () =>
    HttpResponse.json({
      data: postBonusForDepositFindResponse,
      paging: {
        page: 0,
        count: 0,
        total: 3,
      },
    }),
)
