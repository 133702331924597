import { filterObject } from '@st/utils'
import { setUtmCookie, getCookie } from '../utils/setUtmCookies'

interface PartnerParams {
  partnerId?: string
  bannerId?: string
  campaignId?: string
  data1?: string
  data2?: string
  channel?: string
}

/**
 * В телеграм мини-апп нельзя нормально передавать query
 * Только один параметр tgWebAppStartParam с лимитом 64 символа
 * Для партнерки мы кодируем его как p_aid_bid_cid_data1_data2_channel
 * Где p_ - это префикс,которые говорит что дальше идут параметры партнерки
 */
function getTelegramPartnerParams(): PartnerParams {
  const params = new URLSearchParams(globalThis.location.search)

  const startParam = params.get('tgWebAppStartParam') || ''

  const [prefix, partnerId, bannerId, campaignId, data1, data2, channel] =
    startParam.split('_')

  if (prefix !== 'p') {
    return {}
  }

  return filterObject(
    {
      partnerId,
      bannerId,
      campaignId,
      data1,
      data2,
      channel,
    },
    Boolean,
  )
}

function getQueryPartnerParams(): PartnerParams {
  const params = new URLSearchParams(globalThis.location.search)

  return filterObject(
    {
      partnerId: params.get('a_aid') || undefined,
      bannerId: params.get('a_bid') || undefined,
      campaignId: params.get('a_cid') || undefined,
      data1: params.get('data1') || undefined,
      data2: params.get('data2') || undefined,
      channel: params.get('chan') || undefined,
    },
    Boolean,
  )
}
function getCookiePartnerParams(): PartnerParams {
  return filterObject(
    {
      partnerId: getCookie('PAPaid'),
      bannerId: getCookie('PAPbid'),
      campaignId: getCookie('PAPcid'),
      data1: getCookie('PAPdata1'),
      data2: getCookie('PAPdata2'),
      channel: getCookie('PAPchan'),
    },
    Boolean,
  )
}

export function usePartnerProgram() {
  const stFetch = useRawStFetch()

  function visitByPartner() {
    const body = {
      ...getTelegramPartnerParams(),
      ...getQueryPartnerParams(),
      ...getCookiePartnerParams(),
    }

    const params = new URLSearchParams(globalThis.location.search)
    const referrer = params.get('referrer') || document.referrer

    stFetch('/partner-program/post-affiliate/visit', {
      method: 'post',
      body,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Partner-Referrer': referrer,
      },
    })
  }

  function saveUtmCookies() {
    const params = new URLSearchParams(globalThis.location.search)
    const utmParams = {
      utm_campaign: params.get('utm_campaign') ?? '',
      utm_medium: params.get('utm_medium') ?? '',
      utm_source: params.get('utm_source') ?? '',
      utm_content: params.get('utm_content') ?? '',
    }

    setUtmCookie(utmParams)
  }

  return {
    visitByPartner,
    saveUtmCookies,
  }
}
