import type { Custom } from 'markets-store/classes/CustomMenu/types'
import { useMarketsBookStore } from 'markets-store/marketsbook/store/marketsBookStore'
import { useSettingsStore } from '../stores/useSettingsStore'

/*
  Для оптимизации большая часть глобальных и доступных для
  неавторизованных юзеров данных получаются в запросе /init
  Этот плагин делает запрос /init и раскидывет данные по сторам и тд
 */
export default defineNuxtPlugin({
  name: 'init-plugin',
  order: 2,
  async setup() {
    const { data, error } = await useStFetch('/init')
    const { $marketsStore } = useNuxtApp()
    const { setSettings } = useSettingsStore()
    const { fetchMarketsConfigs } = useMarketsBookStore()

    if (data.value) {
      setSettings(data.value.systemSettings)
      $marketsStore.customMenu.setCustoms(data.value.sbCustomMenu as Custom[])
    } else {
      console.error('init request failed', error.value)
    }

    onNuxtReady(() => {
      if (data.value) {
        fetchMarketsConfigs(data.value.systemSettings)
      }
    })

    return {
      provide: {
        init: data.value,
      },
    }
  },
})
