import { useUserStore } from '@st/user/stores/useUserStore'
import { useTagsStore } from '@st/user/stores/useTagsStore'
import { delay } from '@st/utils'

/**
 * Показываем модалку с формой верификации новым юзерам
 */
export default defineNuxtPlugin({
  name: 'verificationFormPlugin',
  async setup() {
    const userStore = useUserStore()
    const tagsStore = useTagsStore()
    const router = useRouter()
    const route = useRoute()

    async function showFormIfNeeded() {
      await until(
        () =>
          userStore.isReady &&
          tagsStore.isReady &&
          !route.path.includes('promo'),
      ).toBeTruthy()

      const isRequiredVerificationForm =
        userStore.user?.kycLevel === -1 &&
        // @ts-expect-error TODO: remove this line after the release of the new version of the API
        !tagsStore.hasTag('skipKycLevelCheckOnDeposit')

      if (!isRequiredVerificationForm) return

      await delay(2000)
      router.replace({
        query: { modal: 'userVerification' },
      })
    }

    watch(
      () => userStore.isAuthenticated,
      (newValue, oldValue) => {
        if (newValue && !oldValue) showFormIfNeeded()
      },
      {
        immediate: true,
      },
    )
  },
})
