import { useLocalStorage } from '@st/use/composables'

export default defineNuxtPlugin({
  name: 'referralCode',
  setup() {
    const referralCodeStorage = useLocalStorage<string>('referralCode', '')

    onNuxtReady(() => {
      const route = useRoute()
      const code = route.query.ref

      if (code) {
        referralCodeStorage.value = String(code)
      }
    })
  },
})
