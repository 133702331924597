export default defineNuxtPlugin((nuxt) => {
  const route = useRoute()
  const intercomLoaded = ref(false)

  onNuxtReady(() => {
    const config = useRuntimeConfig()
    const apiKey = config.public.intercomKey

    if (!apiKey) {
      console.error('NUXT_PUBLIC_INTERCOM_KEY is missing!')
      return
    }

    /* eslint-disable */
    /*
      чтобы не вставлять инлайн скрипт на 100500 строк
      сам widget.intercom.io грузится через useHead
      блок кода ниже (взят из инлайн интеграции скрипта)
      необходим для создания функции Intercom в window
      после загрузки скрипта, эта функция обогощается методами
      (эта функция необходима в window до загрузки скрпита)
    */
    var i = function () {
      i.c(arguments)
    } as any
    ;(i.q = []),
      (i.c = function (n: any) {
        i.q.push(n)
      }),
      (window.Intercom = i)
    /* eslint-enable */

    useHead(() => ({
      script: [
        {
          src: `https://widget.intercom.io/widget/${apiKey}`,
          async: true,
          load: () => {
            intercomLoaded.value = true
          },
        },
      ],
    }))

    const { initChat, updateChatSession } = useIntercom()

    watchEffect(() => {
      if (intercomLoaded.value) {
        initChat()
      }
    })
    watch(
      () => route.path,
      () => {
        updateChatSession()
      },
    )
  })

  nuxt.vueApp.provide('intercomLoaded', intercomLoaded)

  return {
    provide: {
      intercomLoaded,
    },
  }
})
