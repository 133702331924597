export default defineNuxtPlugin(() => {
  const apiEventBus = useApiEventBus()
  const router = useRouter()
  const stFetch = useRawStFetch()

  apiEventBus.on('responseError', (context) => {
    // eslint-disable-next-line no-underscore-dangle
    const response = context.response?._data ?? {}

    if (response.error !== 'FORBIDDEN_COUNTRY') return

    router.replace({
      query: {
        modal: 'restriction',
        ...response.data,
      },
    })
  })

  onNuxtReady(() => {
    stFetch('/geo/availability/assert', {})
  })
})
