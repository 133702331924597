import type { components } from '@st/api/paths'
import { useUserStore } from './useUserStore'

export type UserTag = components['schemas']['TagType']

export const useTagsStore = defineStore('tags', () => {
  const stFetch = useRawStFetch()
  const tags = ref<UserTag[]>([])
  const isReady = ref(false)

  const tagsAsSet = computed(() => new Set(tags.value))

  async function fetchTags() {
    const { data, error } = await stFetch('/user/tags', { method: 'post' })
    isReady.value = true

    if (error) {
      console.error('FAILED TO FETCH USER TAGS')
    }

    tags.value = data
  }

  function hasTag(tag: UserTag) {
    return tagsAsSet.value.has(tag)
  }

  const { isAuthenticated } = storeToRefs(useUserStore())
  watchEffect(() => {
    if (isAuthenticated.value) {
      fetchTags()
    } else {
      tags.value = []
    }
  })

  return {
    hasTag,
    isReady,
  }
})
