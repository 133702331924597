<template>
  <i class="st-icon" :data-t="dataT" />
</template>

<script setup lang="ts">
import type { IconName } from './types'
import * as icons from './icons'

type Props = {
  /** width and height of icon */
  size?: number | string
  /** for tests */
  dataT?: string
} & (
  | {
      /** Name of icon */
      name: IconName
      svg?: string
      color?: string
    }
  | {
      /** String representation of svg */
      svg: string
      name?: string
      color?: string
    }
)

const props = withDefaults(defineProps<Props>(), {
  size: 24,
  dataT: 'st-icon',
})

const icon = computed(() => {
  const camelIconName = props.name?.replace(/-./g, (x) =>
    x[1].toUpperCase(),
  ) as keyof typeof icons
  return icons[camelIconName]
})

const size = computed(() => ({
  height: `${props.size}px`,
  width: `${props.size}px`,
}))

const base64 = computed(() =>
  props.svg ? `data:image/svg+xml;base64,${btoa(props.svg)}` : '',
)

const url = computed(() =>
  base64.value || icon.value ? `url("${base64.value || icon.value}")` : 'none',
)

const type = computed(() =>
  props.name && /^f-|^c-/.test(props.name) && !props.svg
    ? 'colored'
    : 'regular',
)

const background = computed(() => {
  if (url.value === 'none') return 'transparent'
  if (props.color) return props.color

  return type.value === 'colored'
    ? `no-repeat ${url.value} transparent`
    : 'currentColor'
})
</script>

<style scoped>
.st-icon {
  display: inline-block;

  width: v-bind('size.height');
  height: v-bind('size.height');

  background: v-bind(background);

  mask-image: v-bind(url);
  mask-position: center;
  mask-repeat: no-repeat;
  mask-size: contain;
}
</style>
