<template>
  <Teleport to="body">
    <div v-if="isPopupActive" class="cookie-wrapper">
      <div class="cookie-alert">
        <div class="cookie-info">
          <div class="cookie-title">
            <StIcon name="cookie" /> {{ t('cookies.title') }}
          </div>
          <p class="cookie-text">
            {{ t('cookies.description') }}
          </p>
        </div>
        <div class="cookie-panel">
          <StButton
            class="cookie-settings-button"
            :label="t('cookies.settingsButton')"
            size="m"
            type="text-secondary"
            @click="onSettingsClick"
          />
          <StButton
            :label="t('cookies.rejectAllButton')"
            size="l"
            type="gray"
            @click="handleRejectClick"
          />
          <StButton
            :label="t('cookies.confirmAll')"
            size="l"
            type="primary"
            @click="handleApplyAllClick"
          />
        </div>
      </div>
    </div>
  </Teleport>
</template>

<script setup lang="ts">
import { useCookiesStore } from '../../stores/useCookiesStore'

const { t } = useI18n()
const { cookiesStorage } = storeToRefs(useCookiesStore())
const { configCookie, isCookieConfigured } = useCookiesStore()
const router = useRouter()
const route = useRoute()

const isPopupActive = ref(false)

function handleApplyAllClick() {
  isPopupActive.value = !isPopupActive.value
  configCookie()
}

function handleRejectClick() {
  cookiesStorage.value.analytic = false
  cookiesStorage.value.functional = false

  configCookie()
  isPopupActive.value = !isPopupActive.value
}

function onSettingsClick() {
  router.replace({ query: { modal: 'cookies' } })
  isPopupActive.value = !isPopupActive.value
}

const { isMobile } = usePlatform()

onMounted(() => {
  if (isMobile.value || route.fullPath.includes('promo')) return
  isPopupActive.value = !isCookieConfigured
})
</script>

<style scoped>
.cookie-wrapper {
  position: fixed;
  z-index: 999;
  bottom: 0;

  display: flex;
  justify-content: center;

  width: 100vw;
}

.cookie-alert {
  display: flex;
  gap: var(--spacing-600);
  align-items: center;

  max-width: 1248px;
  padding: var(--spacing-250) var(--spacing-500);

  background: var(--background-primary);
  border-radius: var(--border-radius-150, 12px) var(--border-radius-150, 12px)
    var(--border-radius-0, 0) var(--border-radius-0, 0);
  box-shadow:
    8px 0 16px -4px rgb(17 17 24 / 64%),
    -8px 0 16px -4px rgb(17 17 24 / 64%),
    0 -8px 16px -8px rgb(17 17 24 / 64%);

  @media screen and (width <= 1024px) {
    flex-direction: column;
    align-items: flex-start;
    padding: var(--spacing-250);
  }
}

.cookie-title {
  display: flex;
  gap: var(--spacing-075);
  align-items: center;
  font: var(--desktop-text-md-semibold);
}

.cookie-text {
  margin: var(--spacing-150) 0 0;
  font: var(--desktop-text-sm-medium);
  color: var(--text-secondary);
}

.cookie-panel {
  display: flex;
  gap: var(--spacing-150);

  & .st-button {
    width: max-content;
  }
}
</style>
