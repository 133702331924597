import { hash } from '@st/utils'
import type { TypedUseFetch } from '../nuxtOpenapiTypeHelpers'
import type { paths } from '../paths'
import { useApiConfig } from './useApiConfig'

export const useStFetch: TypedUseFetch<paths> = (url, options) => {
  const { createApiConfig } = useApiConfig()
  const config = createApiConfig(options as any)

  return useFetch(url, {
    key:
      options?.key ??
      hash({
        url,
        method: toValue(config.method),
        body: toValue(config.body),
      }),
    ...(config as any),
  })
}
