/* eslint-disable */

type Listener<T> = (payload: T) => void | Promise<void>

export class EventBus<EventMap extends Record<string, unknown | undefined>> {
  #listeners: {
    [EventName in keyof EventMap]?: Set<Listener<EventMap[EventName]>>
  }

  constructor() {
    this.#listeners = {}
  }

  on<EventName extends keyof EventMap>(
    eventName: EventName,
    listener: Listener<EventMap[EventName]>,
  ) {
    if (!(eventName in this.#listeners)) {
      this.#listeners[eventName] = new Set()
    }
    this.#listeners[eventName]?.add(listener)
  }

  off<EventName extends keyof EventMap>(
    eventName: EventName,
    listener: Listener<EventMap[EventName]>,
  ) {
    this.#listeners[eventName]?.delete(listener)
  }

  emit<EventName extends keyof EventMap>(
    eventName: undefined extends EventMap[EventName] ? EventName : never,
  ): void
  emit<EventName extends keyof EventMap>(
    eventName: EventName,
    payload: EventMap[EventName],
  ): void
  emit<EventName extends keyof EventMap>(
    eventName: EventName,
    payload?: EventMap[EventName],
  ) {
    const listeners = this.#listeners[eventName]
    if (!listeners) return
    listeners.forEach((listener) => listener(payload as any))
  }
}
